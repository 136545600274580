import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerQCTOLearnershipForm } from '../../etqa/program/registration/LearnerQCTOLearnershipForm';


const LearnershipRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerQCTOLearnership extends Component {


    constructor(props) {
        super(props);
        this.state = {
            model: {
                //SponsorshipID: utils.getCurrentRole() == "Provider" ? { Id: 1, Description: "SETA Funded" } : { Id: 2, Description: "Industry Funded" },
                SDProvider: null,
                WAID: null,
                ACID: null
            },
            LearnershipDetails: {
                LearnershipProgrammeDescription: "",
                LearnershipProgrammeCode: ""
            },
            messageStyle: "message",
            id: null,
            showTable: true,
            clearOptions: false,
            CurrentProgramme: this.props.location.state.QCTOLearnershipID,
            CreateNewFlag: this.props.location.state.hideFields,
            Learner: this.props.location.state.LearnerID
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon' },
                        { id: 'documents', label: 'DOCUMENTS', tooltip: 'Documents', disabled: (this.props.location.state.hideFields), visible: !(this.props.location.state.hideFields) }
                    ]
                }
            ]
        }
    };

    componentDidMount() {
        if (utils.isNull(this.props.location.state.QCTOLearnershipID) || this.props.location.state.QCTOLearnershipID === 0)
        {
            this.defaultSponsorship()
        }
    }

    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            this.setState({
                LearnershipSetupDetails: {
                    LearnershipProgrammeCode: values.LearnershipID,
                    LearnershipProgrammeDescription: values.LearnershipTitle
                },
                Learner: this.props.location.state.LearnerID
            });

            let newValues = null;
            if (!utils.isNull(values)) {
                newValues = { ...values };
            }
            console.log(newValues);
            if (!utils.isNull(newValues)) {
                if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                    this.setState({
                        model: newValues,
                    });
                }
            }

            this.WACCheck(values);
        }
    }

    WACCheck(values) {
        if (!utils.isNull(values)) {
            if (!utils.isNull(values.LeadWAID)) {
                let url = "api/sims/learner/WorkplaceApprovalContactCheck?WAID=" + values.LeadWAID.Id;
                utils.invokeUrl(url, (response) => {
                    if (response.Result != "") {
                        this.setState({
                            message: response.Result,
                            messageStyle: "ERROR",
                        })
                    }
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }
    }

    onChangeSDProviderIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.SDProviderContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeWAIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.WAContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeACIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.ACContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeWAIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.WAContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    onChangeEmployerIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.EmployerContactID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    clearOptionsHandler = () => {
        this.setState({ clearOptions: false });
    }



    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        } else if ("documents" === buttonId) {
            this.props.history.push({
                pathname: '/learnerQCTOLearnershipDocuments',
                state: {
                    LearnerID: this.props.location.state.LearnerID,
                    LearnerQCTOLearnershipID: this.props.location.state.QCTOLearnershipID,
                    hideFields: false,
                    learnerNames: this.props.location.state.learnerNames,
                    permissions: this.props.location.state.permissions
                }
            });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    defaultSponsorship = () => {
        let url = "api/sims/LearnerQCTOLearnership/GetCurrentRole";
        utils.invokeUrl(url, (response) => {
            if (response.Result === "Skills Development Provider" || response.Result === "AET Agent" || response.Result === "Agent") {
                this.setState({
                    model: {
                        SponsorshipID: { Id: 2, Description: "Industry Funded" }
                    }
                })
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    save() {
        let formValue = LearnershipRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerqctolearnership/LinkLearnerQCTOLearnership", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {
        this.props.history.push({
            pathname: '/LearnerQCTOLearnershipSearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New QCTO Learnership > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update QCTO Learnership > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "80vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerQCTOLearnershipForm ref={LearnershipRef}
                                            viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            hideFields={this.state.CreateNewFlag}
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                            lid={this.props.location.state.LearnerID}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            clearOptions={this.state.clearOptions}
                                            clearOptionsHandler={this.clearOptionsHandler}
                                            model={this.state.model}
                                            data={this.state.model}
                                            onChangeSDProviderIDHandler={this.onChangeSDProviderIDHandler}
                                            onChangeWAIDHandler={this.onChangeWAIDHandler} 
                                            onChangeACIDHandler={this.onChangeACIDHandler}
                                            onChangeEmployerIDHandler={this.onChangeEmployerIDHandler}/>
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(AddLearnerQCTOLearnership);
